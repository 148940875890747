import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Input
} from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import homeBanner from "../../assets/img/HomeBanner1.png"
import homeBannerPattern from "../../assets/svg/home-banner-pattern.svg"
import IconFacebook from "../../assets/svg/icon-facebook.svg"
import IconInstagram from "../../assets/svg/icon-instagram.svg"
import IconLinkedin from "../../assets/svg/icon-linkedin.svg"
import IconYouTube from "../../assets/svg/icon-youtube.svg"

import Socials from "../../constants/socials"
import { searchRestaurantApi } from "services/fasterDriver"
import { debounce } from "lodash"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

function Banner() {
  // const [searchText, setSearchText] = useState("")
  const searchText = useRef("")
  const searchBoxRef = useRef(null)
  const [restaurantList, setRestaurantList] = useState([])
  // const restaurantList = useRef([])

  const handleSearch = debounce(() => {
    if(searchText.current !==""){
    searchRestaurantApi({ input: searchText.current })
      .then(({ ok, data }) => {
        if (ok) setRestaurantList(data)
      })
      .catch(error => console.log(error))
    }else setRestaurantList([])
  },300)

  const history = useHistory()

  

  const handleSelect = (restaurantName)=>{
    // if(restaurantName!=="") 
    searchBoxRef.current.value=restaurantName
    setRestaurantList([])
  }

  // useEffect(()=>{
  //   console.log("ref",searchText.current)
  //   handleSearch()
  // },[searchText.current])

  // useEffect(()=>{
  //   handleSearch()
  // },[searchText])


  return (
    <Box
      w="100%"
      display={"block"}
      h={"100%"}
      overflow={"hidden"}
      position={"relative"}
    >
      <Flex
        w="100%"
        h={{ sm: "initial", md: "95vh" }}
        bg={"primary.500"}
        pt={{ base: "10%", md: "0%" }}
        px={{ base: "3%", md: "6%", xl: "10%" }}
        flexDir={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        position={"relative"}
      >
        
        <Flex
          px={{ base: "0px", md: "5%" }}
          flexWrap={"wrap"}
          w={{ base: "100%", md: "50%" }}
          justifyContent={"flex-start"}
          alignItems={"space-between"}
          gap={{
            base: "10",
            md: "81"
          }}
        >
          
          <Flex flexDir={"column"} gap={{base:"10", md:"5"}}>
            
            <Heading as={"h2"} color="white" size={"2xl"} >
              Lower Costs,
              <br />
              Higher Earnings
              <br />
              Faster Drivers
            </Heading>
          

           
            <Box
              w={"100%"}
              pos={"relative"}
              zIndex={{ base: "1000", "2xl": "100" }}
              h={'160px'}
            >
              <Input
                borderRadius="12px"
                bg={"white"}
                placeholder={"Search..."}
                h={"48px"}
                // value={searchText.current}
                onChange={e => {
                  // setSearchText(e.target.value)
                  searchText.current = e.target?.value
                  handleSearch()
                }}
                ref={searchBoxRef}
              />
              <Button
                bg={"primary.500"}
                color={"white"}
                h={"44px"}
                w={"116px"}
                borderRadius={"10px"}
                variant={"primary"}
                position={"absolute"}
                right={"2px"}
                top={"2px"}
                zIndex={"10"}
                onClick={() => history.push('/signin')}
              >
                Search
              </Button>
{/* searchText.current!=="" &&  */}
              {restaurantList?.length>0 && (
                <Box 
                bg={"#ffffff"} 
                mt={"2px"} 
                p={"5px"} 
                borderRadius={"10px"}
                maxH={"150px"}
                overflow={"auto"}
                 >
                  {restaurantList.map((data,index)=>{
                    return(
                      <Box
                      borderRadius={"10px"}
                      p={"3px"}
                      px={"5px"}
                      _hover={{ bg: "#00AEEF", color: "#ffffff" }}
                      key={`search-rest-${index}`}
                      onClick={() =>handleSelect(data.name)
                      }
                    >
                     {data.name}
                    </Box>
                    )
                    
                  })}
                  {/* <Box
                    borderRadius={"10px"}
                    p={"3px"}
                    px={"5px"}
                    _hover={{ bg: "#00AEEF", color: "#ffffff" }}
                  >
                    Test Restaurant
                  </Box>
                  <Box p={"3px"} _hover={{ bg: "#00AEEF", color: "#ffffff" }}>
                    Test Restaurant2
                  </Box> */}
                </Box>
              )}
            </Box>
           
          </Flex>
          {/* </div> */}

          
          <Flex gap={"4"} zIndex={{ base: "1000", "2xl": "1" }}>
            <a href={Socials.Facebook} target={"_blank"}>
              <Center
                bg={"primary.600"}
                w={"48px"}
                h={"48px"}
                borderRadius={"50%"}
              >
                <Image src={IconFacebook} alt={"facebook"} />
              </Center>
            </a>
            <a href={Socials.LinkedIn} target={"_blank"}>
              <Center
                bg={"primary.600"}
                w={"48px"}
                h={"48px"}
                borderRadius={"50%"}
              >
                <Image src={IconLinkedin} alt={"linkedin"} />
              </Center>
            </a>
            <a href={Socials.Instagram} target={"_blank"}>
              <Center
                bg={"primary.600"}
                w={"48px"}
                h={"48px"}
                borderRadius={"50%"}
              >
                <Image src={IconInstagram} alt={"instagram"} />
              </Center>
            </a>
            <a href={Socials.Youtube} target={"_blank"}>
              <Center
                bg={"primary.600"}
                w={"48px"}
                h={"48px"}
                borderRadius={"50%"}
              >
                <Image
                  src={IconYouTube}
                  alt={"youtube"}
                  w={"42px"}
                  h={"42px"}
                />
              </Center>
            </a>
          </Flex>
          
        </Flex>
        {/* </span> */}

        <Image src={homeBanner} w={{ base: "100%", md: "50%" }} zIndex={"20"} />
      </Flex>

      <Box pos={"absolute"} right={"0"} top={"8%"} h={"120%"}>
        <Image
          src={homeBannerPattern}
          h={"100%"}
          fit={"cover"}
          objectPosition={"right"}
        />
      </Box>
    </Box>
  )
}

export default Banner
