import {
  Button,
  Flex,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  useToast
} from "@chakra-ui/react"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useApi } from "../../../services/fasterDriver"
import SettingsLayout from "../../Common/Settings"

export default function MyAccount() {
  const { user } = useSelector(state => state.auth)
  const [beErrors, setBeErrors] = useState({})
  const api = useApi()
  const toast = useToast()

  const [name, setName] = useState(user.name || "")
  const [email, setEmail] = useState(user.email || "")
  const [pending, setPending] = useState(false)

  const save = () => {
    if (name && email) {
      setPending(true)
      const nameParts = name?.split(" ")
      api
        .updateProfileApi({
          name,
          email,
          first_name: nameParts?.[0],
          last_name: nameParts.slice(1)?.join(" ")
        })
        .then(({ ok, data }) => {
          if (ok) {
            toast({
              title: "Success",
              description: "Your profile has been updated",
              status: "success"
            })
            api.fetchUserApi()
          } else {
            setBeErrors({ ...beErrors, ...data })
          }
        })
        .finally(() => {
          setPending(false)
        })
    }
  }

  return (
    <SettingsLayout>
      <Flex
        direction={"column"}
        w={{ base: "90%", md: "60%" }}
        alignItems={"center"}
        gap={4}
      >
        <Heading>My Account</Heading>
        {/*<Flex justifyContent={'center'}>*/}
        {/*  <Image src={profile.photo} w={24} borderRadius={'50%'}/>*/}
        {/*</Flex>*/}
        <FormControl isInvalid={Boolean(beErrors?.name)}>
          <FormLabel>FULL NAME</FormLabel>
          <Input
            type={"text"}
            bg={"gray.100"}
            border={"none"}
            value={name}
            onChange={e => {
              setBeErrors({ ...beErrors, name: "" })
              setName(e.target.value)
            }}
          />
          <FormErrorMessage>{beErrors?.name}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(beErrors?.email)}>
          <FormLabel>EMAIL</FormLabel>
          <Input
            type={"email"}
            bg={"gray.100"}
            border={"none"}
            value={email}
            disabled
          />
          <FormErrorMessage>{beErrors?.email}</FormErrorMessage>
        </FormControl>
        <Flex mt={10} w={"100%"} justifyContent={"center"}>
          <Button
            variant={"primary"}
            bg={"primary.500"}
            color={"white"}
            w={"80%"}
            h={14}
            fontWeight={400}
            onClick={save}
            isLoading={pending}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </SettingsLayout>
  )
}
