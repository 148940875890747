import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Box,
  Spinner
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import renderHTML from "react-render-html"
import { API_ENDPOINT } from "../../constants/endpoints"
import { Heading } from "@chakra-ui/react"

export default function Faqs() {
  const [content, setContent] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetch(API_ENDPOINT + "faq/")
      .then(res => res.json())
      .then(data => {
        setLoading(false)
        Array.isArray(data) ? setContent(data) : setContent([])
      })
  }, [])

  const renderAccordionItem = faqs => {
    return (
      <>
        {faqs
          ?.sort((a, b) => b?.sequence - a?.sequence)
          ?.map(item => (
            <AccordionItem key={item?.id}>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize="1.5rem"
                    fontWeight="600"
                  >
                    {item?.question || ""}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{item?.answer || ""}</AccordionPanel>
            </AccordionItem>
          ))}
      </>
    )
  }

  return (
    <Flex bg={"white"} justifyContent={"center"} w={"100%"} p={10}>
      <Flex
        direction="column"
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        width={"100%"}
        gap={8}
      >
        <Heading
          as="h1"
          size="4xl"
          noOfLines={1}
          textAlign="left"
          alignSelf={"flex-start"}
        >
          FAQs
        </Heading>
        {loading ? (
          <Spinner />
        ) : content?.length > 0 ? (
          <Accordion width="100%" allowToggle allowMultiple>
            {content?.map(faqByCategory => (
              <>{renderAccordionItem(faqByCategory?.faqs)}</>
            ))}
          </Accordion>
        ) : (
          <Box color={"#FE0000"} fontSize={"1.5rem"}>
            No FAQs found
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
