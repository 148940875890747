import {Flex, FormControl, FormLabel, Switch, Button,useToast} from "@chakra-ui/react";
import SettingsLayout from "../../Common/Settings"
import { useSelector } from "react-redux"
import { useEffect,useState } from "react";
import { useApi } from "../../../services/fasterDriver"

export default function OrderAcceptance () {
  const { user } = useSelector(state => state.auth)
  const [autoAcceptStatus, setAutoAcceptStatus] = useState(user?.restaurant?.automatic_accept_order) 
  const [pending,setPending] = useState(false)
  const api = useApi()
  const toast = useToast()

 

  const save = () => {
      setPending(true)
      api
        .updateProfileApi({
          "restaurant.automatic_accept_order": autoAcceptStatus
        })
        .then(({ ok, data }) => {
          if (ok) {
            toast({
              title: "Success",
              description: "Order acceptance has been updated",
              status: "success"
            })
            api.fetchUserApi()
          } else {
            toast({
              title: "Error",
              description: "Updation failed! Try again.",
              status: "Error"
            })
          }
        })
        .finally(() => {
          setPending(false)
        })
   
  }

  return <SettingsLayout>
    <Flex direction={'column'} w={{base: '90%', md: '50%'}} alignItems={'center'} gap={4}>
      <FormControl display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <FormLabel>Automatically Accept</FormLabel>
        <Switch colorScheme={'primary'} defaultChecked={autoAcceptStatus} onChange={()=>setAutoAcceptStatus(!autoAcceptStatus)}/>
      </FormControl>
      <FormControl display={'flex'}  >
        <Button
            variant={"primary"}
            bg={"primary.500"}
            color={"white"}
            w={"50%"}
            h={14}
            mx={'auto'}
            fontWeight={400}
            onClick={()=>save()}
            isLoading={pending}
          >
            Save
        </Button>
      </FormControl>
      
    </Flex>
  </SettingsLayout>
}
