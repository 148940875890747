import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Icon,
  Textarea,
  useToast,
  FormErrorMessage
} from "@chakra-ui/react"
import { useEffect, useRef, useState, useMemo } from "react"
import { BiPencil } from "react-icons/bi"
import { useSelector } from "react-redux"
import { useApi } from "../../../services/fasterDriver"
import SettingsLayout from "../../Common/Settings"
import { FaHotel } from "react-icons/fa"
import { set } from "lodash"
import useForm from "hooks/Form"

export default function MyRestaurant() {
  const {
    user: { restaurant }
  } = useSelector(state => state.auth)
  const imageRef = useRef(null)
  const api = useApi()
  const toast = useToast()

  const [data, setData] = useState({})
  const [photo, setPhoto] = useState(restaurant?.photo || "")
  const [photoFile, setPhotoFile] = useState(null)
  const [changed, setChanged] = useState(false)
  const [beErrors, setBeErrors] = useState({})

  useEffect(() => {
    setData({ //data acts as payload for apicall
      "restaurant.name": restaurant?.name,
      "restaurant.street": restaurant?.street || "",
      "restaurant.city": restaurant?.city || "",
      "restaurant.state": restaurant?.state || "",
      "restaurant.zip_code": restaurant?.zip_code || "",
      "restaurant.phone": restaurant?.phone || "",
      "restaurant.website": restaurant?.website || "",
      "restaurant.description": restaurant?.description || "",
      "restaurant.type": restaurant?.type || "",
      "restaurant.ein_number": restaurant?.ein_number || "",
      "restaurant.tax_rate": restaurant?.tax_rate || ""
      // "restaurant.photo": restaurant?.photo,
    })
    setPhoto(restaurant?.photo || "")
  }, [restaurant])

  const stateSchema = useMemo(() => {
    return {
      "restaurant.name": {
        value: restaurant?.name || "",
        error: ""
      },
      "restaurant.street": {
        value: restaurant?.street || "",
        error: ""
      },
      "restaurant.city": {
        value: restaurant?.city || "",
        error: ""
      },
      "restaurant.state": {
        value: restaurant?.state || "",
        error: ""
      },
      "restaurant.zip_code": {
        value: restaurant?.zip_code || "",
        error: ""
      },
      "restaurant.website": {
        value: restaurant?.website || "",
        error: ""
      },
      "restaurant.phone": {
        value: restaurant?.phone || "",
        error: ""
      },
      "restaurant.type": {
        value: restaurant?.type || "",
        error: ""
      },
      "restaurant.ein_number": {
        value: restaurant?.ein_number || "",
        error: ""
      },
      "restaurant.tax_rate": {
        value: restaurant?.tax_rate || "",
        error: ""
      },
      "restaurant.description": {
        value: restaurant?.description || "",
        error: ""
      }
    }
  }, [restaurant])

  const validationSchema = {
    "restaurant.name": {
      required: true
    },
    "restaurant.street": {
      required: true
    },
    "restaurant.city": {
      required: true
    },
    "restaurant.state": {
      required: true
    },
    "restaurant.zip_code": {
      required: true
    },
    "restaurant.website": {
      required: true
    },
    "restaurant.phone": {
      required: true
    },
    "restaurant.type": {
      required: true
    },
    "restaurant.ein_number": {
      required: false
    },
    "restaurant.tax_rate": {
      required: true
    },
    "restaurant.description": {
      required: false
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationSchema
  )



  useEffect(() => {
    Object.keys(data).length !==0  && setData({ ...data,
      "restaurant.name": state?.["restaurant.name"]?.value,
      "restaurant.street": state?.["restaurant.street"]?.value,
      "restaurant.city": state?.["restaurant.city"]?.value,
      "restaurant.state": state?.["restaurant.state"]?.value,
      "restaurant.zip_code": state?.["restaurant.zip_code"]?.value,
      "restaurant.website": state?.["restaurant.website"]?.value,
      "restaurant.phone": state?.["restaurant.phone"]?.value,
       "restaurant.type": state?.["restaurant.type"]?.value,
       "restaurant.ein_number": state?.["restaurant.ein_number"]?.value,
       "restaurant.tax_rate": state?.["restaurant.tax_rate"]?.value,
       "restaurant.description": state?.["restaurant.description"]?.value,
       })
  }, [state])

  const changeData = (key, value) => {
    const keyParts = key?.split(".")
    setBeErrors({
      ...beErrors,
      restaurant: { ...(beErrors?.restaurant || []), [keyParts[1]]: null }
    })
    handleOnChange(key, value)
    /*if(
      key === "restaurant.name" || 
      key === "restaurant.street" || 
      key === "restaurant.city" || 
      key === "restaurant.state" || 
      key === "restaurant.zip_code" || 
      key === "restaurant.website" || 
      key === "restaurant.phone" || 
      key === "restaurant.type"|| 
      key === "restaurant.ein_number"|| 
      key === "restaurant.tax_rate"|| 
      key === "restaurant.description"
      ) handleOnChange(key, value)
    else setData({ ...data, [key]: value })*/
    setChanged(true)
  }

  const changePhoto = e => {
    const file = e.target.files[0]
    if (FileReader && file) {
      const fr = new FileReader()
      fr.onload = () => {
        setPhoto(fr.result)
        setChanged(true)
        setPhotoFile(file)
      }
      fr.readAsDataURL(file)
    }
  }

  const save = () => {
    const taxRate = Number(data["restaurant.tax_rate"]) || 0
    if (typeof taxRate === "number" && !(taxRate >= 0 && taxRate <= 100)) {
      toast({
        title: "Update failed",
        description: "Tax rate should be in-between 0 to 100.",
        status: "error",
        duration: 3000
      })
      return
    }
    setChanged(false)
    let _data = { ...data }
    if (photoFile) {
      _data["restaurant.photo"] = photoFile
    }
    if (!_data["restaurant.website"]?.startsWith("http")) {
      _data["restaurant.website"] = "https://" + _data["restaurant.website"]
    }
    api
      .updateProfileApi(_data)
      .then(({ ok, data }) => {
        if (ok) {
          toast({
            title: "Update successful",
            description: "Your profile has been updated successfully",
            status: "success",
            duration: 3000
          })
          api.fetchUserApi()
        } else {
          setBeErrors({ ...beErrors, ...data })
          toast({
            title: "Update failed",
            description:
              data?.non_field_errors ||
              "An error occurred while updating your profile information. Please try again later",
            status: "error",
            duration: 3000
          })
        }
      })
      .finally(() => {
        setChanged(false)
      })
  }

  // console.log("data", data)

  return (
    <SettingsLayout>
      <Flex
        direction={"column"}
        w={{ base: "90%", md: "60%" }}
        alignItems={"center"}
        gap={4}
      >
        <Flex justifyContent={"center"}>
          <Box w={24} h={24} position={"relative"}>
            {photo ? (
              <Image
                src={photo}
                w={24}
                h={24}
                borderRadius={"50%"}
                objectFit={"cover"}
              />
            ) : (
              <Icon
                as={FaHotel}
                color="gray.400"
                cursor="pointer"
                w="100%"
                h="100%"
              />
            )}
            <Flex
              position={"absolute"}
              top={1}
              right={1}
              zIndex={100}
              bg={"white"}
              borderRadius={"50%"}
              cursor={"pointer"}
              w={6}
              h={6}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => imageRef.current?.click()}
            >
              <BiPencil />
            </Flex>
            <Input
              type={"file"}
              display={"none"}
              ref={imageRef}
              onChange={changePhoto}
              accept={".png,.jpg,.bmp,.gif,.jpeg"}
            />
          </Box>
        </Flex>
        <FormControl isInvalid={Boolean(state?.["restaurant.name"]?.error || beErrors?.restaurant?.name)}>
          <FormLabel>RESTAURANT NAME
          <span
              className="required"
              style={{ color: "#d32f2f", paddingTop: "4px" }}
            >
              &nbsp;*
            </span>
          </FormLabel>
          <Input
            type={"text"}
            bg={"gray.100"}
            border={"none"}
            // value={data["restaurant.name"]}
            value={state?.["restaurant.name"]?.value}
            onChange={e => changeData("restaurant.name", e.target.value)}
          />
          <FormErrorMessage>{state?.["restaurant.name"]?.error || beErrors?.restaurant?.name}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(state?.["restaurant.street"]?.error || beErrors?.restaurant?.street)}>
          <FormLabel>STREET
          <span
              className="required"
              style={{ color: "#d32f2f", paddingTop: "4px" }}
            >
              &nbsp;*
            </span>
          </FormLabel>
          <Input
            type={"text"}
            bg={"gray.100"}
            border={"none"}
            // value={data["restaurant.street"]}
            value={state?.["restaurant.street"]?.value}
            onChange={e => changeData("restaurant.street", e.target.value)}
          />
          <FormErrorMessage>{ state?.["restaurant.street"]?.error || beErrors?.restaurant?.street}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(state?.["restaurant.city"]?.error || beErrors?.restaurant?.city)}>
          <FormLabel>CITY
          <span
              className="required"
              style={{ color: "#d32f2f", paddingTop: "4px" }}
            >
              &nbsp;*
            </span>
          </FormLabel>
          <Input
            type={"text"}
            bg={"gray.100"}
            border={"none"}
            // value={data["restaurant.city"]}
            value={state?.["restaurant.city"]?.value}
            onChange={e => changeData("restaurant.city", e.target.value)}
          />
          <FormErrorMessage>{state?.["restaurant.city"]?.error || beErrors?.restaurant?.city}</FormErrorMessage>
        </FormControl>
        <Flex justifyContent={"space-between"} w={"100%"} gap={4}>
          <FormControl isInvalid={Boolean(state?.["restaurant.state"]?.error ||  beErrors?.restaurant?.state)}>
            <FormLabel>STATE
            <span
              className="required"
              style={{ color: "#d32f2f", paddingTop: "4px" }}
            >
              &nbsp;*
            </span>
            </FormLabel>
            <Input
              type={"text"}
              bg={"gray.100"}
              border={"none"}
              // value={data["restaurant.state"]}
              value={state?.["restaurant.state"]?.value}
              onChange={e => changeData("restaurant.state", e.target.value)}
            />
            <FormErrorMessage>{state?.["restaurant.state"]?.error || beErrors?.restaurant?.state}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(state?.["restaurant.zip_code"]?.error || beErrors?.restaurant?.zip_code)}>
            <FormLabel>ZIP CODE
            <span
              className="required"
              style={{ color: "#d32f2f", paddingTop: "4px" }}
            >
              &nbsp;*
            </span>
            </FormLabel>
            <Input
              type={"number"}
              bg={"gray.100"}
              border={"none"}
              // value={data["restaurant.zip_code"]}
              value={state?.["restaurant.zip_code"]?.value}
              onChange={e => changeData("restaurant.zip_code", e.target.value)}
            />
            <FormErrorMessage>
              {state?.["restaurant.zip_code"]?.error || beErrors?.restaurant?.zip_code}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <FormControl isInvalid={Boolean(state?.["restaurant.website"]?.error || beErrors?.restaurant?.website)}>
          <FormLabel>WEBSITE
            <span
              className="required"
              style={{ color: "#d32f2f", paddingTop: "4px" }}
            >
              &nbsp;*
            </span>
          </FormLabel>
          <Input
            type={"text"}
            bg={"gray.100"}
            border={"none"}
            // value={data["restaurant.website"]}
            // onChange={e => changeData("restaurant.website", e.target.value)}
            value={state?.["restaurant.website"]?.value}
            onChange={e => changeData("restaurant.website", e.target.value)}
          />
          <FormErrorMessage>{state?.["restaurant.website"]?.error ||  beErrors?.restaurant?.website}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(state?.["restaurant.ein_number"]?.error || beErrors?.restaurant?.ein_number)}>
          <FormLabel>EIN NUMBER
          </FormLabel>
          <Input
            type={"number"}
            bg={"gray.100"}
            border={"none"}
            // value={data["restaurant.ein_number"]}
            value={state?.["restaurant.ein_number"]?.value}
            onChange={e => changeData("restaurant.ein_number", e.target.value)}
          />
          <FormErrorMessage>
            {state?.["restaurant.ein_number"]?.error ||  beErrors?.restaurant?.ein_number}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(state?.["restaurant.phone"]?.error || beErrors?.restaurant?.phone)}>
          <FormLabel>PHONE NUMBER
          <span
              className="required"
              style={{ color: "#d32f2f", paddingTop: "4px" }}
            >
              &nbsp;*
            </span>
          </FormLabel>
          <Input
            // type={"tel"}
            type={"number"}
            bg={"gray.100"}
            border={"none"}
            // value={data["restaurant.phone"]}
            value={state?.["restaurant.phone"]?.value}
            onChange={e => changeData("restaurant.phone", e.target.value)}
          />
          <FormErrorMessage>{state?.["restaurant.phone"]?.error || beErrors?.restaurant?.phone}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(state?.["restaurant.type"]?.error || beErrors?.restaurant?.type)}>
          <FormLabel>
            TYPE OF THE RESTAURANT
            <span
              className="required"
              style={{ color: "#d32f2f", paddingTop: "4px" }}
            >
              &nbsp;*
            </span>
          </FormLabel>
          <Input
            type={"text"}
            bg={"gray.100"}
            border={"none"}
            // value={data["restaurant.type"]}
            value={state?.["restaurant.type"]?.value}
            // onChange={(e)=>handleInputChange("restaurant.type",e.target.value)}
            onChange={e => changeData("restaurant.type", e.target.value)}
          />
          <FormErrorMessage>{state?.["restaurant.type"]?.error || beErrors?.restaurant?.type}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(state?.["restaurant.tax_rate"]?.error || beErrors?.restaurant?.tax_rate)}>
          <FormLabel>TAX PERCENTAGE
          <span
              className="required"
              style={{ color: "#d32f2f", paddingTop: "4px" }}
            >
              &nbsp;*
            </span>
          </FormLabel>
          <Input
            type={"number"}
            min={0}
            bg={"gray.100"}
            border={"none"}
            placeholder="0"
            // value={Number(data["restaurant.tax_rate"]) || ""}
            value={Number(state?.["restaurant.tax_rate"]?.value) || ""}
            onChange={e => changeData("restaurant.tax_rate", e.target.value)}
          />
          <FormErrorMessage>{state?.["restaurant.tax_rate"]?.error || beErrors?.restaurant?.tax_rate}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(state?.["restaurant.description"]?.error || beErrors?.restaurant?.description)}>
          <FormLabel>DESCRIPTION</FormLabel>
          <Textarea
            bg={"gray.100"}
            border={"none"}
            rows={4}
            // value={data["restaurant.description"]}
            value={state?.["restaurant.description"]?.value}
            onChange={e => changeData("restaurant.description", e.target.value)}
          />
          <FormErrorMessage>
            {state?.["restaurant.description"]?.error || beErrors?.restaurant?.description}
          </FormErrorMessage>
        </FormControl>
        <Flex mt={10} w={"100%"} justifyContent={"center"}>
          <Button
            variant={"primary"}
            bg={"primary.500"}
            color={"white"}
            w={"80%"}
            h={14}
            fontWeight={400}
            onClick={save}
            disabled={!changed || disable}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </SettingsLayout>
  )
}
