import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { Rating } from "react-simple-star-rating"
import { ORDER_STATUS, ORDER_STATUS_DISPLAY } from "../../../constants/orders"
import { useApi } from "../../../services/fasterDriver"
import PayOrderDrawer from "./PayOrderDrawer"
import RateOrderDrawer from "./RateOrderDrawer"
import help from "../../../assets/svg/help.svg"

export default function CustomerOrders() {
  const api = useApi()
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [payOrder, setPayOrder] = useState(null)
  const [rateOrder, setRateOrder] = useState(null)
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(1)
  const pageSize = 5

  const fetchOrders = needRefresh => {
    setOrders([])
    setLoading(true)
    api
      .getOrdersApi(undefined, page, pageSize)
      .then(({ data, ok }) => {
        if (ok) {
          setTotalRows(data?.count)
          if (data?.count > pageSize * page) {
            setPage(page + 1)
          }
          setOrders(prev => [...prev, ...(data?.results || [])])
        } else {
          setOrders([])
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchOrders(true)
  }, [])

  // const unpaidOrders = orders.filter(it => it.status === ORDER_STATUS.Unpaid)
  // const pendingOrders = orders.filter(it =>
  //   [
  //     ORDER_STATUS.Pending,
  //     ORDER_STATUS.InTransit,
  //     ORDER_STATUS.InProgress
  //   ].includes(it.status)
  // )
  // const completedOrders = orders.filter(it =>
  //   [ORDER_STATUS.Delivered].includes(it.status)
  // )

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }} gap={0}>
      <Heading size={"lg"} my={2}>
        {" "}
        All Orders
      </Heading>
      <InfiniteScroll
        dataLength={orders?.length}
        next={fetchOrders}
        hasMore={loading || orders?.length < totalRows}
        loader={
          <h4 style={{ width: "100%", textAlign: "center" }}>Loading...</h4>
        }
        scrollableTarget="scrollableDiv"
        endMessage={
          <p style={{ width: "100%", textAlign: "center", padding: "1rem" }}>
            {!loading && !totalRows
              ? "No orders found."
              : "No more orders to load."}
          </p>
        }
      >
        {orders.map(order => {
          if (order.status === ORDER_STATUS.Unpaid) {
            return (
              <Order
                order={order}
                key={order.id}
                firstAction={() => setPayOrder(order)}
                statusBgColor="red"
                statusTextColor="white"
              />
            )
          }

          if (
            [
              ORDER_STATUS.Pending,
              ORDER_STATUS.InTransit,
              ORDER_STATUS.InProgress
            ].includes(order.status)
          ) {
            return (
              <Order
                order={order}
                key={order.id}
                statusBgColor="yellow"
                statusTextColor="black"
              />
            )
          }

          if ([ORDER_STATUS.Delivered].includes(order.status)) {
            return (
              <Order
                order={order}
                key={order.id}
                secondAction={() => setRateOrder(order)}
                statusBgColor="green"
                statusTextColor="white"
              />
            )
          }

          return (
            <Order
              order={order}
              key={order.id}
              statusBgColor="primary.500"
              statusTextColor="white"
            />
          )
        })}
      </InfiniteScroll>

      {/* {
        unpaidOrders.length > 0 && <>
          <Heading size={'lg'} my={2}>Unpaid Orders</Heading>
          {unpaidOrders.map((order) => <Order order={order} key={order.id} firstAction={() => setPayOrder(order)}/>)}
          <Divider/>
        </>
      }
      {
        pendingOrders.length > 0 && <>
          <Heading size={'lg'} my={2}>Pending Orders</Heading>
          {pendingOrders.map((order) => <Order order={order} key={order.id}/>)}
          <Divider/>
        </>
      }
      {
        completedOrders.length > 0 && <>
          <Heading size={'lg'} my={2}>Completed Orders</Heading>
          {orders.filter(it => [ORDER_STATUS.Delivered].includes(it.status)).map((order) => <Order order={order} key={order.id} secondAction={() => setRateOrder(order)}/>)}
        </>
      } */}

      <PayOrderDrawer
        order={payOrder}
        onFinish={() => {
          fetchOrders(true)
          setPayOrder(null)
        }}
      />
      <RateOrderDrawer
        order={rateOrder}
        onFinish={() => {
          setRateOrder(null)
          fetchOrders(true)
        }}
      />
    </Flex>
  )
}

function Order({
  order,
  firstAction,
  secondAction,
  statusBgColor,
  statusTextColor
}) {
  const {
    id,
    status,
    restaurant,
    dishes,
    total,
    restaurant_reviewed,
    driver_reviewed,
    special_instructions
  } = order

  const itemCount = dishes.reduce((acc, dish) => acc + dish.quantity, 0)

  const [dishDetails, setDishDetails] = useState([])

  const fetchDishDetails = () => {
    const dishDetails = dishes.map(dish => ({
      ...(dish.dish_detail || {}),
      quantity: dish?.quantity
    }))
    setDishDetails(dishDetails)
  }

  useEffect(() => {
    fetchDishDetails()
  }, [])

  return (
    <Grid
      templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }}
      gridGap={0}
      py={4}
      // borderTopWidth={1}
      borderBottomWidth={1}
    >
      <GridItem colSpan={3}>
        <Flex w={"100%"} gap={8}>
          <Flex w={"30%"}>
            <Image
              src={restaurant?.photo}
              w={"260px"}
              h={"200px"}
              borderRadius={10}
            />
          </Flex>
          <Flex direction={"column"} gap={2} w={"70%"}>
            <Flex justifyContent={"space-between"} w={"100%"}>
              <Flex flexDirection="column">
                <Box>{restaurant?.name}</Box>
                <Text fontSize={"12px"} color={"gray.500"}>
                  Total ${total} For {itemCount} Item{itemCount > 1 ? "s" : ""}
                </Text>
              </Flex>
              {restaurant?.rating && (
                <Flex w={"40%"} gap={2}>
                  <Rating
                    size={24}
                    iconsCount={1}
                    initialValue={restaurant.rating / 5}
                    readonly={true}
                    SVGstrokeColor={"#0093D9"}
                    SVGstorkeWidth={1}
                    allowFraction={true}
                    fillColor={"#0093D9"}
                  />
                  <Text>{Number(restaurant?.rating).toFixed(1)}</Text>
                </Flex>
              )}
            </Flex>
            <Flex direction={"column"} gap={2} w={"100%"}>
              {dishDetails.map(dd => (
                <Flex gap={2} key={dd?.id}>
                  <Image
                    src={dd.image_1 || help}
                    h={"60px"}
                    w={"60px"}
                    objectFit={"cover"}
                  />
                  <Flex flexDirection={"column"} gap={1} w={"70%"}>
                    <Flex gap={1}>
                      {dd?.quantity > 1 ? (
                        <Text color={"primary.500"}>{dd?.quantity}x</Text>
                      ) : (
                        ""
                      )}{" "}
                      {dd?.name}
                    </Flex>
                    <Text fontSize={"12px"} color={"gray.500"} noOfLines={2}>
                      {dd?.description}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
            <Flex direction={"column"} gap={0} w={"100%"}>
              <Text fontSize={"12px"}>Special Instructions:</Text>
              <Text fontSize={"12px"} color={"gray.500"} noOfLines={2}>
                {special_instructions}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem>
        <Flex flexDirection={"column"} gap={4}>
          {status === ORDER_STATUS.Unpaid && (
            <Button
              bg={"primary.500"}
              color={"white"}
              w={"200px"}
              onClick={firstAction}
            >
              Pay
            </Button>
          )}
          {status === ORDER_STATUS.Delivered &&
            (!restaurant_reviewed || !driver_reviewed) && (
              <Button
                variant={"outline"}
                borderColor={"black"}
                w={"200px"}
                onClick={secondAction}
              >
                Rate Your Order
              </Button>
            )}

          <Text
            bg={statusBgColor}
            color={statusTextColor}
            t
            fontSize={"20px"}
            sx={{
              padding: "10x",
              textAlign: "center",
              borderRadius: "20px",
              width: "200px"
            }}
          >
            {" "}
            {ORDER_STATUS_DISPLAY[status] || status}{" "}
          </Text>
        </Flex>
      </GridItem>
    </Grid>
  )
}
